export const FAQList = [
  {
    label: 'Do I need to print out my label?',
    text:
      'All packages must have a pre-paid label. If you do not have a physical return label with the package, you can upload your label from the confirmation email you will receive or Returnmates will contact you via email to get the digital label once the pickup has been completed',
  },
  {
    label: 'How much does it cost?',
    text: 'During the promotional period, pickups are only $1.50 per package!',
  },
  {
    label: 'Does my return need to be packaged in a sealed box?',
    text:
      "We encourage you to package and label your return before putting in the locker. But if you just want to put the item in the locker without packaging it up, we'll take care of that for you!",
  },
  {
    label: 'Who should I contact if I have questions or issues with my return?',
    text:
      'Once the item has been picked up from the locker, feel free to text Returnmates at (562) 317-8868 with questions or issues.',
  },
  {
    label: 'Will I receive confirmation that my return has been processed?',
    text:
      "Yes! You'll receive an e-mail when Returnmates has successfully processed your return. Returnmates will also reach out to you if there are issues with your return.",
  },
  {
    label: 'Are there any limitations on what I can return through the locker?',
    text:
      'As long as it fits in the locker, and is not a dangerous, illegal or perishable good, you are welcome to return it through our lockers! ',
  },
  {
    label: 'How long does it take for my return to process?',
    text:
      "Each return time depends on how long it takes for your retailer to process the return. We'll ship your item out same day or latest next morning after picking up your items",
  },
]
