import { makeStyles } from '@mui/styles'
import { MIDNIGHT_EXPRESS, SLATE_GREY } from '@returnmates/client-core/src/constants/colors'

import { POPPINS } from '../../constants/styles/fonts'

const useStyles = makeStyles(() => ({
  modalContainer: {
    display: 'flex',
  },
  leftPart: {
    width: '50%',
    maxHeight: 471,
    '& > img': {
      height: 471,
      width: '100%',
      objectFit: 'cover',
      objectPosition: 'cover',
    },
  },
  rightPart: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0 26px',
    width: '50%',
  },
  title: {
    marginTop: 20,
    fontFamily: POPPINS,
    fontWeight: 600,
    fontSize: 24,
    lineHeight: '140%',
    color: MIDNIGHT_EXPRESS,
    textAlign: 'center',
  },
  subTitle: {
    marginTop: 14,
    fontFamily: POPPINS,
    fontWeight: 500,
    fontSize: 14,
    lineHeight: '160%',
    color: SLATE_GREY,
    textAlign: 'center',
  },
  button: {
    marginTop: 20,
    width: 200,
  },

  modal: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    width: '100%',
    maxWidth: 680,
    fontFamily: POPPINS,
    flexDirection: 'column',
    overflow: 'hidden',
  },
  paper: {
    width: 680,
    maxWidth: 680,
  },
  '@media screen and (max-width: 800px)': {
    modal: {
      overflowX: 'hidden',
      overflow: 'auto',
    },
    modalContainer: {
      flexDirection: 'column',
      height: '100%',
    },
    paper: {
      width: 375,
      maxWidth: 375,
    },
    leftPart: {
      width: '100%',
      height: 290,
      '& > img': {
        height: 290,
        width: '100%',
        objectFit: 'cover',
        objectPosition: '0px -75px',
      },
    },
    rightPart: {
      width: 'auto',
      padding: '20px 20px 44px',
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
    },
    title: {
      fontSize: 30,
    },
    subTitle: {
      fontSize: 14,
      maxWidth: 230,
    },
    button: {
      width: '100%',
      marginTop: '30px',
    },
  },
  '@media screen and (max-width: 450px)': {
    paper: {
      width: '100%',
      maxWidth: '100%',
    },
    leftPart: {
      width: '100%',
      height: 290,
      '& > img': {
        height: '100%',
        width: '100%',
        objectFit: 'cover',
        objectPosition: '0px -75px',
      },
    },
    subTitle: {
      marginBottom: 30,
    },
    button: {
      width: '100%',
      marginTop: 'auto',
    },
  },

  '@media screen and (max-height: 700px)': {
    title: {
      marginTop: 10,
    },
    leftPart: {
      height: 270,
    },
    rightPart: {
      padding: '0 20px 30px',
    },
  },
}))

export default useStyles
