import clsx from 'clsx'
import { memo } from 'react'

import useStyles from './styles'

const howItWorksPoints = [
  {
    text: 'Select Return/ Ship a package on Kiosk',
  },
  {
    text: 'Take a photo of your package',
  },
  {
    text: 'Place package/ items in the Locker',
  },
  {
    text: "Receive tracking link once it's been shipped!",
  },
]

function HowItWorks() {
  const classes = useStyles()

  return (
    <section className={classes.howItWorks}>
      <div className={clsx(classes.sectionContainer, classes.howItWorksWrap)}>
        <div className={classes.leftPart}>
          <h2 className={classes.howItWorksTitle}>How it works</h2>
          <div className={classes.pointsBlock}>
            {howItWorksPoints.map((item, idx) => (
              <div className={classes.howItWorkItemBlock} key={idx}>
                <div className={classes.howItWorksItemNumber}>{idx + 1}</div>
                <p className={classes.howItWorksItem}>{item.text}</p>
              </div>
            ))}
          </div>
        </div>
        <div className={classes.rightPart}>
          <img
            className={classes.howItWorksImage}
            src="/assets/images/girlNearLockers.jpg"
            alt="lockers"
          />
        </div>
      </div>
    </section>
  )
}

export default memo(HowItWorks)
