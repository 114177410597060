import { makeStyles } from '@mui/styles'
import {
  BRIGHT_GRAY,
  GOLD,
  RAISIN_BLACK,
  ROBO_MASTER,
} from '@returnmates/client-core/src/constants/colors'
import { OSWALD, POPPINS } from '@returnmates/ui-core/src/constants/styles/fonts'

import { DEFAULT_MAX_WIDTH } from '../../constants/styles'

const useStyles = makeStyles(() => ({
  container: {},
  topPart: {
    position: 'relative',
    backgroundImage: `url(/assets/images/wall.jpg)`,
    backgroundRepeat: 'no-repeat',
    '-webkit-background-size': 'cover',
    '-moz-background-size': 'cover',
    '-o-background-size': 'cover',
    backgroundSize: 'cover',
    backgroundPosition: 'bottom',
    minHeight: 587,
    overflow: 'hidden',
  },
  header: {
    height: 120,
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  headerBackground: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
    background: 'linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 106.96%)',
    opacity: 0.2,
  },
  main: {
    display: 'flex',
    height: '100%',
  },
  logoWrap: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    maxWidth: 312,
  },
  partnerLogoWrap: {
    display: 'flex',
    alignItems: 'center',
  },
  logo: {
    height: 29,
    '& > path': {
      fill: '#FFFFFF',
    },
  },
  cross: {
    margin: '0 34px',
  },
  luxerLogo: {
    marginTop: 6,
  },
  title: {
    fontFamily: OSWALD,
    fontWeight: 600,
    fontSize: 56,
    lineHeight: '130%',
    color: '#FFFFFF',
    textTransform: 'uppercase',
    padding: '80px 95px 0 0',
    maxWidth: 565,
    '& > span': {
      color: GOLD,
    },
  },
  lockersImageWrap: {
    height: 460,
    width: '100%',
  },
  lockersImage: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    width: '100%',
    maxWidth: 621,
  },
  benefits: {
    padding: '95px 172px 62px',
  },
  sectionContainer: {
    maxWidth: DEFAULT_MAX_WIDTH,
    margin: '0 auto',
  },
  topPartWrapper: {
    padding: '0 30px',
  },
  benefitsTitle: {
    fontFamily: OSWALD,
    fontWeight: 600,
    fontSize: 40,
    lineHeight: '130%',
    textTransform: 'uppercase',
    textAlign: 'center',
    color: RAISIN_BLACK,
    '& > span': {
      color: GOLD,
    },
  },
  text: {
    fontFamily: POPPINS,
    fontWeight: 400,
    fontSize: 16,
    lineHeight: '160%',
    textAlign: 'center',
    color: RAISIN_BLACK,
    margin: '16px auto 0',
    maxWidth: 615,
  },
  freeTrialBlock: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '40px auto 0',
    maxWidth: 390,
    width: '100%',
    backgroundColor: 'rgba(242, 169, 0, 0.06)',
    padding: '13px 16px 19px',
    boxSizing: 'border-box',
    backgroundImage: `repeating-linear-gradient(-18deg, ${GOLD}, ${GOLD} 7px, transparent 7px, transparent 14px, ${GOLD} 14px), repeating-linear-gradient(65deg, ${GOLD}, ${GOLD} 7px, transparent 7px, transparent 14px, ${GOLD} 14px), repeating-linear-gradient(144deg, ${GOLD}, ${GOLD} 7px, transparent 7px, transparent 14px, ${GOLD} 14px), repeating-linear-gradient(245deg, ${GOLD}, ${GOLD} 7px, transparent 7px, transparent 14px, ${GOLD} 14px)`,
    backgroundSize: '1px 100%, 100% 1px, 1px 100% , 100% 1px',
    backgroundPosition: '0 0, 0 0, 100% 0, 0 100%',
    backgroundRepeat: 'no-repeat',
  },
  prevPrice: {
    position: 'relative',
    fontFamily: POPPINS,
    fontWeight: 600,
    fontSize: 30,
    lineHeight: '130%',
    width: 46,
    textAlign: 'center',
    color: ROBO_MASTER,
    '&:before': {
      content: '""',
      position: 'absolute',
      top: '53%',
      left: 0,
      transform: 'translateY(-50%)',
      width: '100%',
      height: 3,
      backgroundColor: ROBO_MASTER,
    },
  },
  freeTrialLabel: {
    position: 'relative',
    color: GOLD,
    fontSize: 16,
    fontFamily: POPPINS,
    fontWeight: 700,
    marginTop: 4,
    textTransform: 'uppercase',
  },
  freeTrialText: {
    fontFamily: POPPINS,
    fontWeight: 600,
    fontSize: 30,
    lineHeight: '130%',
    color: '#22222299',
    marginTop: 7,
  },
  leftArrows: {
    '&:before, &:after': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: -25,
      width: 28,
      height: '50%',
      transform: 'skewX(35deg)',
      transformOrigin: 'top left',
      backgroundColor: GOLD,
      zIndex: -1,
    },
    '&:after': {
      transformOrigin: 'bottom left',
      transform: 'skewX(-35deg)',
      top: 'auto',
      bottom: 0,
    },
  },
  rightArrows: {
    '&:before, &:after': {
      content: '""',
      position: 'absolute',
      top: 0,
      right: -25,
      width: 28,
      height: '50%',
      transform: 'skewX(-35deg)',
      transformOrigin: 'top right',
      backgroundColor: GOLD,
      zIndex: -1,
    },
    '&:after': {
      transformOrigin: 'bottom right',
      transform: 'skewX(35deg)',
      top: 'auto',
      bottom: 0,
    },
  },
  benefitsRow: {
    marginTop: 50,
    display: 'flex',
    justifyContent: 'space-between',
  },
  benefitBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minWidth: 150,
  },
  benefitText: {
    fontFamily: OSWALD,
    fontWeight: 500,
    fontSize: 18,
    lineHeight: '130%',
    textTransform: 'uppercase',
    textAlign: 'center',
    color: RAISIN_BLACK,
    marginTop: 36,
    maxWidth: 200,
  },
  benefitIcon: {
    height: 72,
  },
  howItWorks: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: RAISIN_BLACK,
    height: 705,
    position: 'relative',
  },
  leftPart: {
    width: '50%',
    padding: '67px 100px 58px 0px',
    boxSizing: 'border-box',
  },
  rightPart: {
    position: 'absolute',
    top: 0,
    right: 0,
    width: '50%',
    height: '100%',
  },
  howItWorksTitle: {
    fontFamily: OSWALD,
    fontWeight: 600,
    fontSize: 48,
    lineHeight: '130%',
    color: '#FFFFFF',
    textTransform: 'uppercase',
  },
  pointsBlock: {
    marginTop: 47,
  },
  howItWorkItemBlock: {
    display: 'flex',
    alignItems: 'center',
    '&:not(:first-child)': {
      marginTop: 30,
    },
  },
  howItWorksItem: {
    fontFamily: POPPINS,
    fontWeight: 500,
    fontSize: 20,
    lineHeight: '180%',
    color: '#FFFFFF',
  },
  howItWorksItemNumber: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontFamily: POPPINS,
    fontWeight: 600,
    fontSize: 22.5,
    lineHeight: '150%',
    color: '#FFFFFF',
    textAlign: 'center',
    backgroundColor: GOLD,
    width: 40,
    minWidth: 40,
    height: 40,
    borderRadius: '50%',
    marginRight: 19,
  },
  howItWorksImage: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    objectPosition: 'top',
  },
  howItWorksWrap: {
    display: 'flex',
    width: '100%',
    padding: '0 30px',
  },
  questions: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 802,
    margin: '0 auto',
    padding: '85px 130px 144px',
  },
  questionsWrap: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 40,
  },
  dropdownHeadText: {
    '& > span': {
      fontFamily: OSWALD,
      fontWeight: 600,
      fontSize: 20,
      lineHeight: '130%',
      color: RAISIN_BLACK,
      textTransform: 'uppercase',
    },
  },
  dropdownText: {
    color: `${RAISIN_BLACK} !important`,
  },
  questionBlock: {
    width: '100%',
    '&:nth-child(1)': {
      marginRight: 18,
    },
    '&:nth-child(2)': {
      marginLeft: 18,
    },
  },
  questionsImageBlock: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 341,
    width: '100%',
    backgroundColor: BRIGHT_GRAY,
  },
  questionsTextBlock: {
    marginTop: 36,
  },
  questionTitle: {
    fontFamily: OSWALD,
    fontWeight: 600,
    fontSize: 40,
    lineHeight: '130%',
    color: RAISIN_BLACK,
    textTransform: 'uppercase',
  },
  questionText: {
    fontFamily: POPPINS,
    fontWeight: 400,
    fontSize: 16,
    lineHeight: '160%',
    color: RAISIN_BLACK,
    marginTop: 20,
    '& > span': {
      textTransform: 'uppercase',
      color: GOLD,
      fontWeight: 600,
    },
  },
  '@media (max-width: 1680px)': {
    howItWorks: {
      height: 531,
    },
  },
  '@media (max-width: 1288px)': {
    title: {
      maxWidth: 500,
    },
  },
  '@media (max-width: 1180px)': {
    topPartWrapper: {
      padding: 0,
    },
    questionsImageBlock: {
      height: 285,
    },
    leftPart: {
      paddingRight: 50,
    },
    title: {
      textAlign: 'center',
      padding: 0,
      margin: '0 auto',
    },
    main: {
      flexDirection: 'column',
      paddingLeft: 0,
    },
    lockersImageWrap: {
      position: 'relative',
      overflow: 'hidden',
      height: 353,
      marginTop: 36,
    },
    lockersImage: {
      left: -60,
      objectFit: 'cover',
      objectPosition: 'top',
      width: '100%',
      height: '100%',
    },
  },
  '@media (max-width: 1024px)': {
    benefitsRow: {
      flexWrap: 'wrap',
      marginTop: 36,
    },
    benefitBox: {
      width: '50%',
      marginTop: 54,
    },
    howItWorks: {
      height: 'auto',
    },
    howItWorksWrap: {
      flexDirection: 'column',
      height: 'auto',
      padding: 0,
    },
    rightPart: {
      order: 1,
      width: '100%',
      position: 'initial',
    },
    leftPart: {
      order: 2,
      padding: '65px 130px',
      alignSelf: 'flex-start',
      width: '100%',
    },
    howItWorksImage: {
      maxHeight: 531,
    },
  },
  '@media (max-width: 950px)': {
    questionsWrap: {
      flexDirection: 'column',
    },
    questionBlock: {
      '&:nth-child(1)': {
        marginRight: 0,
      },
      '&:nth-child(2)': {
        marginLeft: 0,
        marginTop: 54,
      },
    },
  },
  '@media (max-width: 800px)': {
    benefits: {
      padding: '72px 30px 96px',
    },
    leftPart: {
      padding: '47px 30px 60px',
    },
  },
  '@media (max-width: 640px)': {
    topPart: {
      minHeight: 'auto',
    },
    partnerLogoWrap: {
      position: 'initial',
    },
    title: {
      fontSize: 40,
      padding: '0 20px',
    },
    howItWorksTitle: {
      fontSize: 40,
    },
    lockersImageWrap: {
      height: 300,
    },
    benefitsRow: {
      marginTop: 0,
    },
    benefitBox: {
      width: '100%',
    },
    questions: {
      padding: '66px 30px 49px',
    },
  },
  '@media (max-width: 430px)': {
    lockersImageWrap: {
      height: 204,
    },
    benefitsTitle: {
      fontSize: 30,
    },
    howItWorksTitle: {
      fontSize: 30,
    },
    howItWorksItem: {
      fontSize: 16,
    },
    questionsImageBlock: {
      height: 187,
      '& > svg': {
        height: 92,
        width: 92,
      },
    },
    questionTitle: {
      fontSize: 24,
    },
  },
  '@media (max-width: 340px)': {
    cross: {
      margin: '0 14px',
    },
    luxerLogo: {
      width: '100%',
    },
    logoWrap: {
      padding: '0 10px',
    },
  },
}))

export default useStyles
