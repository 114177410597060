import * as actions from '@returnmates/client-core/src/constants/actionTypes'
import { getUserData } from '@returnmates/client-core/src/selectors/user'
import { PromoDialogTypes } from '@returnmates/client-core/src/type'
import Button from '@returnmates/ui-core/src/components/Button'
import Logo from '@returnmates/ui-core/src/components/images/logo'
import Modal from '@returnmates/ui-core/src/components/Modal'
import clsx from 'clsx'
import { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'

import {
  MAIN,
  MY_ACCOUNT_SCHEDULE_PICKUP,
  PROMO_MAIL,
  PROMO_RTR,
  ZIP_CODE,
} from '../../constants/paths'
import PromoMobileBottom from './PromoMobileButton'
import useStyles from './styles'

function PromoDialog(): JSX.Element {
  const userData = useSelector(getUserData)
  const [dialogType, setDialogType] = useState<PromoDialogTypes | null>(null)
  const [isOpen, setIsOpen] = useState<boolean>()
  const classes = useStyles()
  const location = useLocation()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  useEffect(() => {
    switch (location.pathname) {
      case PROMO_RTR:
        setDialogType(PromoDialogTypes.rtr)
        setIsOpen(true)
        break
      case PROMO_MAIL:
        setDialogType(PromoDialogTypes.mail)
        setIsOpen(true)
        break
      default:
        setIsOpen(false)
        setTimeout(() => {
          setDialogType(null)
        }, 300)
    }
  }, [location])

  const priceInfoText = useMemo(() => {
    if (dialogType === PromoDialogTypes.rtr) {
      return ''
    } else if (dialogType === PromoDialogTypes.mail) {
      return 'Create an account, tell us about your return & we’ll do the rest'
    }
  }, [dialogType])

  const codeText = useMemo(() => {
    if (dialogType === PromoDialogTypes.rtr) {
      return '25% on your membership'
    } else if (dialogType === PromoDialogTypes.mail) {
      return 'Get your FREE pickup'
    }
  }, [dialogType])

  const code = useMemo(() => {
    if (dialogType === PromoDialogTypes.rtr) {
      return 'RTR'
    } else if (dialogType === PromoDialogTypes.mail) {
      return 'MAIL'
    }
  }, [dialogType])

  const buttonText = useMemo(() => {
    if (dialogType === PromoDialogTypes.rtr) {
      return 'Schedule a 25% sale pickup'
    } else if (dialogType === PromoDialogTypes.mail) {
      return 'Schedule FREE pickup'
    }
  }, [dialogType])

  const mobileLabel = useMemo(() => {
    if (dialogType === PromoDialogTypes.rtr) {
      return 'We can handle \nALL your returns!'
    } else if (dialogType === PromoDialogTypes.mail) {
      return 'Schedule a \nPickup in Minutes!'
    }
  }, [dialogType])

  const promoMobileHead = useMemo(() => {
    if (dialogType === PromoDialogTypes.rtr) {
      return 'GET 25% off \nunlimited pickups'
    } else if (dialogType === PromoDialogTypes.mail) {
      return 'FIRST PICKUP is free!'
    }
  }, [dialogType])

  const promoMobileText = useMemo(() => {
    if (dialogType === PromoDialogTypes.rtr) {
      return 'Use code'
    } else if (dialogType === PromoDialogTypes.mail) {
      return 'Use code'
    }
  }, [dialogType])

  const promoCodeText = useMemo(() => {
    if (dialogType === PromoDialogTypes.rtr) {
      return PromoDialogTypes.rtr
    } else if (dialogType === PromoDialogTypes.mail) {
      return PromoDialogTypes.mail
    }
  }, [dialogType])

  const mobileButtonLabel = useMemo(() => {
    if (dialogType === PromoDialogTypes.rtr) {
      return 'Schedule Pickup'
    } else if (dialogType === PromoDialogTypes.mail) {
      return 'Schedule Free Pickup'
    }
  }, [dialogType])

  const closeModal = useCallback(() => {
    setIsOpen(false)
    setTimeout(() => {
      setDialogType(null)
    }, 300)

    navigate(MAIN)
  }, [navigate])

  const onSchedulePickup = useCallback(() => {
    dispatch(actions.setPromoCode.request(dialogType))

    setIsOpen(false)
    setTimeout(() => {
      setDialogType(null)
    }, 300)

    if (userData) {
      navigate(MY_ACCOUNT_SCHEDULE_PICKUP)
    } else {
      navigate(ZIP_CODE)
    }
  }, [userData, navigate, dialogType, dispatch])

  return (
    <Modal
      isOpen={Boolean(dialogType && isOpen)}
      paperClassName={classes.modal}
      onClose={closeModal}
    >
      <div className={classes.root}>
        <div className={classes.noMobile}>
          <Logo className={classes.logo} />
          <p className={classes.label}>
            {dialogType === PromoDialogTypes.rtr ? (
              <>
                <span className={classes.purpleText}>Save 25% </span>
                <span>on your pickups</span>
              </>
            ) : (
              <>
                <span>Schedule Your</span>
                <span className={classes.purpleText}> Free Pickup </span>
                <span>in Minutes!</span>
              </>
            )}
          </p>
          <p
            className={clsx(classes.priceInfo, {
              [classes.noDisplay]: dialogType === PromoDialogTypes.rtr,
            })}
          >
            {priceInfoText}
          </p>
          <img
            className={classes.promoImage}
            src="assets/images/promo-dialog-image.png"
            alt="promo-image"
          />
          <p className={classes.code}>{codeText}</p>
          <p className={classes.codeSecondLine}>
            USE PROMO: <label className={classes.purpleCode}>{code}</label>
          </p>
          <Button
            onClick={onSchedulePickup}
            className={classes.button}
            fullWidth
            label={<span className={classes.schedulePickupLabel}>{buttonText}</span>}
          />
        </div>
        <div className={clsx(classes.mobileRoot, classes.mobile)}>
          <p className={classes.mobileLabel}>{mobileLabel}</p>
          <div className={classes.mobileImageWrapper}>
            <img src="/assets/images/phone.png" alt="phone" className={classes.phone} />
            <img src="/assets/images/track.png" alt="track" className={classes.leftImage} />
          </div>
          <div className={classes.mobilePromo}>
            <div className={classes.mobilePromoHead}>{promoMobileHead}</div>
            <div className={classes.mobilePromoText}>
              {promoMobileText} <span>{promoCodeText}</span>
            </div>
            <div className={classes.mobilePromoBottomText}>
              <PromoMobileBottom dialogType={dialogType} />
            </div>
          </div>
          <Button
            onClick={onSchedulePickup}
            className={classes.button}
            fullWidth
            label={mobileButtonLabel || 'Schedule'}
          />
        </div>
      </div>
    </Modal>
  )
}

export default memo(PromoDialog)
