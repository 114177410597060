import { GOLD } from '@returnmates/client-core/src/constants/colors'
import Dropdown from '@returnmates/ui-core/src/components/Dropdown'
import clsx from 'clsx'
import { memo } from 'react'

import { FAQList } from './constants'
import useStyles from './styles'

function Questions() {
  const classes = useStyles()

  return (
    <section className={classes.questions}>
      <h2 className={classes.questionTitle}>frequently asked questions</h2>
      <div className={clsx(classes.sectionContainer, classes.questionsWrap)}>
        {FAQList.map(item => (
          <Dropdown
            key={item.label}
            text={item.label}
            dropdownText={item.text}
            dropdownHeadStyles={classes.dropdownHeadText}
            dropdownTextStyles={classes.dropdownText}
            headColor={GOLD}
          />
        ))}
      </div>
    </section>
  )
}

export default memo(Questions)
