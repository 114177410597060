import { memo, useEffect, useMemo, useState } from 'react'
import DocumentMeta from 'react-meta-tags'
import { useLocation } from 'react-router-dom'

import { defaultMeta, interopMeta, partnersMeta } from '../../constants/metaTags'

function Middleware() {
  const location = useLocation()
  const [previousPathname, setPreviousPathname] = useState('')

  const partnerMeta = useMemo(
    () =>
      partnersMeta[window.location.hostname.split('.')[0] as keyof typeof partnersMeta] ||
      partnersMeta.default,
    [],
  )

  useEffect(() => {
    if (previousPathname !== location.pathname) {
      window.scrollTo(0, 0)
      setPreviousPathname(location.pathname)
    }
  }, [location.pathname, previousPathname])

  return (
    <DocumentMeta>
      {defaultMeta.map(data => (
        <meta key={data[0]} property={data[0]} content={data[1]} />
      ))}
      {interopMeta.map(data => (
        <meta key={data[0]} itemProp={data[0]} content={data[1]} />
      ))}
      {partnerMeta
        ? partnerMeta.map(data => <meta key={data[0]} itemProp={data[0]} content={data[1]} />)
        : null}
    </DocumentMeta>
  )
}

export default memo(Middleware)
