import { memo } from 'react'

import Footer from '../../components/FooterSmall'
import Benefits from './Benefits'
import HowItWorks from './HowItWorks'
import Questions from './Questions'
import useStyles from './styles'
import TopPart from './TopPart'

function LuxerOneTrial() {
  const classes = useStyles()
  return (
    <div className={classes.container}>
      <TopPart />
      <Benefits />
      <HowItWorks />
      <Questions />
      <Footer color="#000000" />
    </div>
  )
}

export default memo(LuxerOneTrial)
