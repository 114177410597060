import moment from 'moment'
import { memo, useMemo } from 'react'
import { Link } from 'react-router-dom'

import { PRIVACY, PRIVACY_PARTNER, TERMS } from '../../constants/paths'
import useStyles from './styles'

interface Props {
  color?: string
  isUsePartnerPrivacy?: boolean
}

function Footer({ color, isUsePartnerPrivacy }: Props) {
  const classes = useStyles({ color })

  const year = useMemo(() => moment().year(), [])
  return (
    <footer className={classes.footer}>
      <div className={classes.footerWrapper}>
        <span>© Copyright {year}. All rights reserved.</span>
        <div className={classes.termsAndPrimacyWrap}>
          <Link to={TERMS}>Terms & Conditions</Link>
          <span className={classes.separator}>|</span>
          <Link to={isUsePartnerPrivacy ? PRIVACY_PARTNER : PRIVACY}>Privacy Policy</Link>
        </div>
      </div>
    </footer>
  )
}

export default memo(Footer)
