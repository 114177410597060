import { createTheme } from '@mui/material/styles'
import {
  ALICE_BLUE,
  ECHO_BLUE,
  ERROR,
  GHOST_WHITE,
  MIDNIGHT_EXPRESS,
  NEON_BLUE,
  NEON_BLUE_LIGHT,
  PERIWINKLE,
  ROCK_BLUE,
  SLATE_GREY,
  SOLITUDE,
} from '@returnmates/client-core/src/constants/colors'

import { POPPINS } from '../constants/styles/fonts'

declare module '@mui/material/Button/buttonClasses' {
  interface ButtonClasses {
    cancel: string
  }
}

declare module '@mui/material/Button/Button' {
  interface ButtonPropsVariantOverrides {
    secondary: true
    cancel: true
    white: true
  }
}

const theme = createTheme({
  palette: {
    primary: {
      main: NEON_BLUE_LIGHT,
    },
    secondary: {
      main: NEON_BLUE_LIGHT,
    },
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        rounded: {
          borderRadius: 16,
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          color: ROCK_BLUE,
          '&$checked': {
            color: NEON_BLUE_LIGHT,
          },
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          backgroundColor: GHOST_WHITE,
          fontFamily: POPPINS,
          fontWeight: 600,
          fontSize: 11,
          lineHeight: '160%',
          textTransform: 'uppercase',
          color: ECHO_BLUE,
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        head: {
          color: ECHO_BLUE,
        },
      },
    },
    MuiTable: {
      styleOverrides: {
        root: {
          marginTop: 0,
          marginBottom: 0,
        },
      },
    },
    MuiButton: {
      variants: [
        {
          props: { variant: 'secondary' },
          style: {
            backgroundColor: '#000000',
            color: '#FFFFFF',
            padding: '16px 24px',
            '&:hover': {
              backgroundColor: '#000000',
              border: '0px solid #000000',
              '@media (hover: none)': {
                backgroundColor: '#000000',
              },
              '&.Mui-disabled': {
                background: 'rgba(0, 0, 0, 0.4)',
              },
            },
            '&.Mui-disabled': {
              color: '#FFFFFF',
              border: 'none',
              background: 'rgba(0, 0, 0, 0.4)',
              cursor: 'not-allowed',
              pointerEvents: 'all !important',
            },
          },
        },
        {
          props: { variant: 'white' },
          style: {
            backgroundColor: '#FFFFFF',
            color: '#000000',
            padding: '16px 24px',
            '&:hover': {
              backgroundColor: '#FFFFFF',
              color: '#000000',
              border: '0px solid #FFFFFF',
              '@media (hover: none)': {
                backgroundColor: '#FFFFFF',
              },
              '&.Mui-disabled': {
                background: 'rgba(0, 0, 0, 0.4)',
              },
            },
            '&.Mui-disabled': {
              color: '#FFFFFF',
              border: 'none',
              background: 'rgba(0, 0, 0, 0.4)',
              cursor: 'not-allowed',
              pointerEvents: 'all !important',
            },
          },
        },
      ],
      styleOverrides: {
        root: {
          textTransform: 'none',
          backgroundColor: NEON_BLUE_LIGHT,
          fontWeight: 600,
          fontFamily: POPPINS,
          lineHeight: '130%',
          fontSize: 16,
          color: '#FFFFFF',
          borderRadius: 5,
          '&:hover': {
            backgroundColor: NEON_BLUE,
            border: '0px solid #000000',
            '@media (hover: none)': {
              backgroundColor: NEON_BLUE,
            },
          },
        },
        outlined: {
          border: 'none',
          padding: '16px 24px',
          '&:hover': {
            '&.Mui-disabled': {
              background: 'rgba(99, 93, 246, 0.4)',
            },
          },
          '&.Mui-disabled': {
            color: '#FFFFFF',
            border: 'none',
            background: 'rgba(99, 93, 246, 0.4)',
            cursor: 'not-allowed',
            pointerEvents: 'all !important',
          },
        },
        cancel: {
          marginRight: '10px',
          marginLeft: 'auto',
          backgroundColor: ALICE_BLUE,
          border: `1px solid ${SOLITUDE}`,
          color: MIDNIGHT_EXPRESS,
          '&:hover': {
            backgroundColor: ALICE_BLUE,
            border: `1px solid ${NEON_BLUE}`,
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          borderRadius: 4,
          fontSize: 12,
          padding: 0,
          fontWeight: 600,
          boxSizing: 'border-box',
          backgroundColor: '#FFFFFF',
          '& label': {
            backgroundColor: GHOST_WHITE,
            padding: '0 2px',
          },
          '&.Mui-focused': {
            border: `1px solid ${NEON_BLUE_LIGHT}`,
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          border: `1px solid ${PERIWINKLE}`,
          '&.Mui-error': {
            border: `1px solid ${ERROR} !important`,
          },
          '&.Mui-focused': {
            border: `1px solid ${NEON_BLUE_LIGHT}`,
          },
          '&:hover': {
            border: `1px solid ${NEON_BLUE_LIGHT}`,
          },
        },
        notchedOutline: {
          border: 'none',
        },
        inputMultiline: {
          padding: '14px 20px',
          fontFamily: POPPINS,
          fontWeight: 500,
          fontSize: 16,
          lineHeight: '130%',
          '& label': {
            color: 'rgba(146, 155, 172, 0.6)',
          },
        },
        input: {
          padding: '14px 20px',
          fontFamily: POPPINS,
          fontWeight: 500,
          fontSize: 16,
          lineHeight: '130%',
          '& label': {
            color: 'rgba(146, 155, 172, 0.6)',
          },
        },
      },
    },
    // MuiPaginationItem: {
    //   root: {
    //     fontFamily: POPPINS,
    //     color: SLATE_GREY,
    //     fontSize: 16,
    //   },
    //   page: {
    //     '&.Mui-selected': {
    //       backgroundColor: 'transparent',
    //       color: NEON_BLUE_LIGHT,
    //     },
    //     '&.Mui-selected:hover': {
    //       backgroundColor: 'transparent !important',
    //     },
    //     '&:hover': {
    //       backgroundColor: 'transparent',
    //     },
    //   },
    // },
    MuiSwitch: {
      styleOverrides: {
        root: {
          width: 42,
          height: 27,
          padding: 0,
          margin: 10,
          overflow: 'initial',
        },
        switchBase: {
          padding: 1,
          '&$checked': {
            transform: 'translateX(15px)',
            color: '#FFFFFF !important',
            '& + $track': {
              backgroundColor: `${NEON_BLUE_LIGHT} !important`,
              opacity: 1,
              border: 'none',
            },
          },
        },
        thumb: {
          width: 24,
          height: 24,
        },
        track: {
          borderRadius: 26 / 2,
          backgroundColor: SLATE_GREY,
          opacity: '1',
        },
      },
    },
  },
})

export default theme
