export const FAQList = [
  {
    label: 'Do I need to print out my label?',
    text:
      'Upload your digital label on the scheduling page, if you do not have a physical label (luxerone.returnmates.com)',
  },
  {
    label: 'How much does it cost?',
    text:
      'Prices vary by location: There will be a $1/$3 processing fee per return item, based on your location.',
  },
  {
    label: 'Does my return need to be packaged in a sealed box?',
    text: 'No, a box is not required as part of your return but if you have one, please include!',
  },
  {
    label: 'Confirm if this is still accurate',
    text: `Once the item has been picked up from the locker, feel free to text Returnmates at <a href=tel:5623178868>(562) 317-8868</a> with questions or issues.`,
  },
  {
    label: 'Will I receive confirmation that my return has been processed?',
    text:
      "Yes! You'll receive an e-mail when Returnmates has successfully processed your return. Returnmates will also reach out to you if there are issues with your return.",
  },
  {
    label: 'Are there any limitations on what I can return through the locker?',
    text:
      'As long as it fits in the locker, and is not a dangerous, illegal or perishable good, you are welcome to return it through our lockers! ',
  },
  {
    label: 'How long does it take for my return to process?',
    text:
      "Each return time depends on how long it takes for your retailer to process the return. We'll ship your item out same day or latest next morning after picking up your items",
  },
]
