import {
  BrandsWithBox,
  RetailersGroup,
  Tape,
  Users,
} from '@returnmates/ui-core/src/components/imageComponents'
import { memo } from 'react'

import useStyles from './styles'

const benefitsMap = [
  {
    text: 'No post office lines',
    icon: Users,
  },
  {
    text: 'No packaging required',
    icon: Tape,
  },
  {
    text: 'We accept returns from any retailer',
    icon: RetailersGroup,
  },
  {
    text: 'Outbound shipping',
    icon: BrandsWithBox,
  },
]

function Benefits() {
  const classes = useStyles()

  return (
    <section className={classes.benefits}>
      <div className={classes.sectionContainer}>
        <h2 className={classes.benefitsTitle}>
          <span>Online returns</span>
          <br />
          just got a lot easier
        </h2>
        <p className={classes.text}>
          Online returns are a hassle. We've partnered with Returnmates to make it easy! Want to
          skip the post office trip? Don't want to hassle with printing labels and packaging? Just
          put your return in the Luxer locker and we'll take care of the rest!
        </p>
        <div className={classes.freeTrialBlock}>
          <p className={classes.freeTrialText}>$3</p>
          <div className={classes.freeTrialLabelContainer}>
            <p className={classes.freeTrialLabel}>Free trial!</p>
          </div>
        </div>
        <div className={classes.benefitsRow}>
          {benefitsMap.map((benefit, i) => (
            <div key={i} className={classes.benefitBox}>
              <benefit.icon className={classes.benefitIcon} />
              <p className={classes.benefitText}>{benefit.text}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export default memo(Benefits)
