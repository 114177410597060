// It's partly auto generated code for Zingle messenger
/* eslint-disable */
//@ts-nocheck

let o, i, p
const a = []
const c = []

let Zingle = {
  init: function(...arg) {
    o = arg
    const e = {
      then: function(n) {
        return c.push({ type: 't', next: n }), e
      },
      catch: function(n) {
        return c.push({ type: 'c', next: n }), e
      },
    }
    return e
  },
  on: function(...arg) {
    a.push(arg)
  },
  render: function(...arg) {
    i = arg
  },
  destroy: function(...arg) {
    p = arg
  },
}

function s() {
  try {
    let e
    if ((e = 'string' == typeof this.response ? JSON.parse(this.response) : this.response).url) {
      const t = document.getElementsByTagName('script')[0]
      const r = document.createElement('script')
      r.async = false
      r.src = e.url
      t.parentNode.insertBefore(r, t)
    }
  } catch (err) {
    console.error('Connection to zingle fail', err)
  }
}

// @ts-ignore
window.__onWebMessengerHostReady__ = function(n) {
  // @ts-ignore
  if ((delete window.__onWebMessengerHostReady__, (Zingle = n), o)) {
    for (let r = n.init(...o), s = 0; s < c.length; s++) {
      const u = c[s]
      r = 't' === u.type ? r.then(u.next) : r.catch(u.next)
    }
  }
  if (i) {
    // @ts-ignore
    document.render.apply(n, i)
  }
  if (p) {
    // @ts-ignore
    document.destroy.apply(n, p)
  }
  for (let s = 0; s < a.length; s++) {
    n.on(...a[s])
  }
}

const u = new XMLHttpRequest()
u.addEventListener('load', s),
  u.open('GET', 'https://cdn.zingle.me/web-chat/loader.json', !0),
  (u.responseType = 'json'),
  u.send()

Zingle.init({ appId: '6067941bec49df00d2609620' }).then(function() {
  const bgcolor = '#4BD1A0'
  const bizicon = document
    .getElementById('web-messenger-container')
    // @ts-ignore
    .contentWindow.document.querySelector('.app-icon')
  const chaticon = document
    .getElementById('web-messenger-container')
    // @ts-ignore
    .contentWindow.document.querySelector('.messenger-button-icon')

  // replace this html with the svg code of the new business icon you want to use
  const newbizicon =
    '<svg viewBox="0 0 80 80" xmlns="http://www.w3.org/2000/svg" xmlns:bx="https://boxy-svg.com"><path fill="#4BD1A0" d="M0 0h80v80H0z" bx:origin="0 0"/><path d="M40.435 67.967c12.854 0 23.298-10.677 23.298-23.819 0-13.141-10.444-23.819-23.298-23.819-2.571 0-7.391-.033-7.423-.033l-.193 2.727c.193 0 4.981.033 7.616.033 11.376 0 20.663 9.462 20.663 21.125 0 11.63-9.255 21.125-20.663 21.125-2.989 0-9.319-.066-9.384-.066V68c.032-.033 6.363-.033 9.384-.033z" fill="#fff" bx:origin="-0.950095 -0.425457"/><path d="m21.86 22.265 9.525 6.068V16.868l-9.525 5.397zm-5.593-.176L34.164 12v21.518L16.267 22.089z" fill="#fff" bx:origin="-0.908923 -0.557673"/></svg>'

  // replace this html with the svg code of the new chat icon you want to use
  const newchaticon =
    '<svg width="30" height="36" viewBox="0 0 30 36" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M0.807699 7.15495C0.0664062 8.97143 0.0664062 11.2698 0.0664062 15.8667V16.4C0.0664062 21.498 0.0664062 24.047 0.974047 26.0295C1.97272 28.2108 3.7223 29.9604 5.90359 30.9591C7.88605 31.8667 10.4351 31.8667 15.5331 31.8667H19.1823C20.4087 31.8667 21.0219 31.8667 21.6255 31.9404C22.1617 32.0058 22.6917 32.1146 23.2103 32.2657C23.7941 32.4357 24.3577 32.6773 25.485 33.1604L29.9331 35.0667V31.8667V15.8667C29.9331 11.2698 29.9331 8.97143 29.1918 7.15495C28.176 4.66577 26.2007 2.69046 23.7115 1.67464C21.895 0.93335 19.5966 0.93335 14.9997 0.93335C10.4029 0.93335 8.10449 0.93335 6.288 1.67464C3.79882 2.69046 1.82351 4.66577 0.807699 7.15495ZM4.65623 23.5644C4.69817 23.6037 4.79319 23.6929 4.84734 23.7403C4.96625 23.8443 5.13702 23.9863 5.35974 24.1534C5.80504 24.4874 6.45924 24.9224 7.32272 25.3541C9.05316 26.2193 11.6155 27.0667 14.9997 27.0667C18.384 27.0667 20.9463 26.2193 22.6768 25.3541C23.5402 24.9224 24.1945 24.4874 24.6397 24.1534C24.8625 23.9863 25.0332 23.8443 25.1522 23.7403L25.354 23.5543C25.7706 23.1377 25.7706 22.4624 25.354 22.0458C24.9377 21.6295 24.2628 21.6292 23.8462 22.0451L23.7473 22.1348C23.6662 22.2058 23.537 22.3138 23.3597 22.4467C23.005 22.7127 22.4592 23.0777 21.7227 23.446C20.2532 24.1808 18.0155 24.9334 14.9997 24.9334C11.984 24.9334 9.74633 24.1808 8.27677 23.446C7.54025 23.0777 6.99445 22.7127 6.63974 22.4467C6.46246 22.3138 6.33324 22.2058 6.25215 22.1348L6.1513 22.0431C5.73448 21.6299 5.06093 21.6304 4.6455 22.0458C4.22894 22.4624 4.22894 23.1377 4.6455 23.5543L4.65623 23.5644Z" fill="white"/></svg>'

  bizicon.innerHTML = newbizicon
  chaticon.innerHTML = newchaticon

  document
    .getElementById('web-messenger-container')
    // @ts-ignore
    .contentWindow.document.querySelector('.messenger-button-icon').style.backgroundColor = bgcolor
  document
    .getElementById('web-messenger-container')
    // @ts-ignore
    .contentWindow.document.querySelector('.messenger-button-icon').style.display = 'flex'
  document
    .getElementById('web-messenger-container')
    // @ts-ignore
    .contentWindow.document.querySelector('.messenger-button-icon').style.alignItems = 'center'
  document
    .getElementById('web-messenger-container')
    // @ts-ignore
    .contentWindow.document.querySelector('.messenger-button-icon').style.justifyContent = 'center'
  document
    .getElementById('web-messenger-container')
    // @ts-ignore
    .contentWindow.document.querySelector('.header-wrapper').style.backgroundColor = bgcolor
})

export default Zingle
/* eslint-enable */
