import { RTR_PARTNER } from '@returnmates/partner/src/constants/partners'

export const defaultMeta = [
  ['keywords', 'Online returns without leaving home'],
  ['description', 'Online returns without leaving home'],
  ['robots', 'index, follow'],
  ['name', 'index, follow'],
]

export const interopMeta = [
  ['name', 'Reed'],
  ['description', 'Online returns without leaving home'],
  ['image', '//returnmates.s3.us-east-2.amazonaws.com/personal-returns-assistant.png'],
]

export const partnersMeta = {
  [RTR_PARTNER]: [
    ['og:url', 'http://rtr.returnmates.com/'],
    ['og:type', 'website'],
    ['og:title', 'RTR returns powered by Returnmates'],
    ['og:description', 'Return your rentals without leaving home'],
    ['og:image', '//returnmates.s3.us-east-2.amazonaws.com/personal-returns-assistant.png'],
  ],
  ['tradesy']: [
    ['og:url', 'http://tradesy.returnmates.com/'],
    ['og:type', 'website'],
    ['og:title', 'Tradesy shipping powered by Returnmates'],
    ['og:description', 'We pick up, package & ship the items for you'],
    ['og:image', '//returnmates.s3.us-east-2.amazonaws.com/personal-shipping-assistant.png'],
  ],
  ['tb']: [
    ['og:url', 'http://tb.returnmates.com/'],
    ['og:type', 'website'],
    ['og:title', 'Thursday Boots returns powered by Returnmates'],
    ['og:description', 'We pick up, package & ship your returns'],
    ['og:image', '//returnmates.s3.us-east-2.amazonaws.com/personal-returns-assistant.png'],
  ],
  default: [
    ['og:url', 'http://www.returnmates.com/'],
    ['og:type', 'website'],
    ['og:title', 'Thursday Boots returns powered by Returnmates'],
    ['og:description', 'Online returns without leaving home'],
    ['og:image', '//returnmates.s3.us-east-2.amazonaws.com/personal-returns-assistant.png'],
  ],
}
