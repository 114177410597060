import { makeStyles } from '@mui/styles'
import {
  ALICE_BLUE,
  MIDNIGHT_EXPRESS,
  NEON_BLUE_LIGHT,
  PORT_GORE,
  SHAMROCK,
  SLATE_GREY,
  YELLOW_SEA,
} from '@returnmates/client-core/src/constants/colors'

import { POPPINS } from '../../constants/styles/fonts'

const useStyles = makeStyles(() => ({
  modal: {
    width: 488,
    maxWidth: 488,
  },
  root: {
    borderRadius: 30,
    margin: 0,
    overflow: 'hidden',
  },
  noMobile: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '30px 40px 40px',
  },
  logo: {
    width: 200,
  },
  label: {
    marginTop: 12,
    fontFamily: POPPINS,
    fontWeight: 600,
    fontSize: 40,
    lineHeight: '130%',
    color: MIDNIGHT_EXPRESS,
    width: 452,
    textAlign: 'center',
  },
  priceInfo: {
    textAlign: 'center',
    fontFamily: POPPINS,
    fontSize: 16,
    lineHeight: '160%',
    color: SLATE_GREY,
    marginTop: 10,
    padding: '0 10px',
  },
  purpleText: {
    color: NEON_BLUE_LIGHT,
  },
  noDisplay: {
    display: 'none',
  },
  promoImage: {
    width: 326,
    height: 260,
    marginTop: 30,
  },
  code: {
    fontFamily: POPPINS,
    fontWeight: 600,
    fontSize: 18,
    lineHeight: '27px',
    color: MIDNIGHT_EXPRESS,
    marginTop: 30,
  },
  codeSecondLine: {
    fontFamily: POPPINS,
    fontWeight: 400,
    fontSize: 18,
    lineHeight: '27px',
    color: MIDNIGHT_EXPRESS,
  },
  purpleCode: {
    color: NEON_BLUE_LIGHT,
    textDecoration: 'underline',
  },
  button: {
    width: 405,
    marginTop: 20,
  },
  leftImage: {
    position: 'relative',
    height: 272,
    zIndex: 3,
    verticalAlign: 'top',
    marginTop: 50,
    marginBottom: 20,
  },
  schedulePickupLabel: {
    textTransform: 'none',
    fontFamily: POPPINS,
    fontWeight: 500,
    fontSize: 18,
    lineHeight: '160%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  mobile: {
    display: 'none',
  },
  mobileRoot: {
    borderRadius: 20,
    backgroundColor: PORT_GORE,
    paddingTop: 20,
    textAlign: 'center',
  },
  mobileLabel: {
    whiteSpace: 'pre-line',
    fontFamily: POPPINS,
    fontWeight: 'bold',
    fontSize: 30,
    lineHeight: '39px',
    textAlign: 'center',
    color: '#FFFFFF',
  },
  mobileImage: {
    display: 'block',
    width: 200,
    marginTop: 10,
    margin: '0 auto',
  },
  mobilePromo: {
    background: SHAMROCK,
    borderRadius: 20,
    width: 'calc(100% - 20px)',
    margin: '0 auto',
    paddingTop: 18,
    paddingBottom: 12,
  },
  mobilePromoHead: {
    fontFamily: POPPINS,
    fontSize: 40,
    lineHeight: '40px',
    textAlign: 'center',
    color: '#FFFFFF',
    whiteSpace: 'pre-line',
    borderRadius: 18,
  },
  mobilePromoText: {
    width: 292,
    margin: '10px auto 0',
    backgroundColor: ALICE_BLUE,
    border: `2px dashed ${SHAMROCK}`,
    boxSizing: 'border-box',
    borderRadius: 10,
    fontFamily: POPPINS,
    fontWeight: 500,
    fontSize: 30,
    lineHeight: '40px',
    textAlign: 'center',
    color: PORT_GORE,
    '& > span': {
      fontWeight: 700,
      color: YELLOW_SEA,
    },
  },
  mobilePromoBottomText: {
    fontFamily: POPPINS,
    fontSize: 15,
    lineHeight: '22px',
    textAlign: 'center',
    color: '#FFFFFF',
    marginTop: 10,
  },
  phone: {
    position: 'absolute',
    top: 100,
    right: 50,
    height: 300,
  },
  mobileImageWrapper: {
    position: 'relative',
  },
  '@media screen and (max-width: 1050px)': {
    leftImage: {
      height: 200,
    },
    phone: {
      position: 'absolute',
      top: 160,
      right: 50,
      height: 200,
    },
  },
  '@media screen and (max-width: 920px)': {
    modal: {
      width: 'auto',
      maxWidth: 'auto',
      borderRadius: 20,
    },
    button: {
      width: 'calc(100% - 20px)',
      margin: '20px 10px 16px',
      boxSizing: 'border-box',
    },
    noMobile: {
      display: 'none',
    },
    root: {
      width: 360,
      maxWidth: 360,
      borderRadius: 20,
      backgroundColor: PORT_GORE,
    },
    mobile: {
      display: 'block',
    },
    phone: {
      top: 30,
      right: 100,
      height: 80,
    },
    leftImage: {
      height: 100,
    },
  },
}))

export default useStyles
