import { makeStyles } from '@mui/styles'
import { MIDNIGHT_EXPRESS, SHAMROCK } from '@returnmates/client-core/src/constants/colors'
import { POPPINS } from '@returnmates/ui-core/src/constants/styles/fonts'

interface Props {
  color?: string
}

const useStyles = makeStyles(() => ({
  footer: {
    width: '100%',
    position: 'fixed',
    bottom: 0,
    backgroundColor: ({ color }: Props) => (color ? color : MIDNIGHT_EXPRESS),
  },
  footerWrapper: {
    maxWidth: 1100,
    padding: 20,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '0 auto',
    fontFamily: POPPINS,
    fontWeight: 500,
    fontSize: 14,
    lineHeight: '160%',
    color: 'rgba(255, 255, 255, 0.4)',
  },
  termsAndPrimacyWrap: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& > a': {
      transition: 'all ease-in-out .1s',
      color: 'rgba(255, 255, 255, 0.4)',
      '&:hover': {
        color: SHAMROCK,
      },
    },
  },
  separator: {
    margin: '0 12px',
  },
  '@media (max-width: 700px)': {
    footer: {
      position: 'relative',
    },
  },
  '@media (max-width: 650px)': {
    footerWrapper: {
      flexDirection: 'column-reverse',
    },
    termsAndPrimacyWrap: {
      marginBottom: 5,
    },
  },
}))

export default useStyles
