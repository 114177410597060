import clsx from 'clsx'
import { memo } from 'react'

import Header from './Header'
import useStyles from './styles'

function TopPart() {
  const classes = useStyles()

  return (
    <section className={classes.topPart}>
      <Header />
      <div className={clsx(classes.sectionContainer, classes.topPartWrapper)}>
        <div className={classes.main}>
          <h1 className={classes.title}>
            You can now use Luxer One lockers for <span>online returns!</span>
          </h1>
          <div className={classes.lockersImageWrap}>
            <img className={classes.lockersImage} src="/assets/images/lockers.png" alt="lockers" />
          </div>
        </div>
      </div>
    </section>
  )
}

export default memo(TopPart)
