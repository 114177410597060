import {
  BrandsWithBox,
  RetailersGroup,
  Users,
} from '@returnmates/ui-core/src/components/imageComponents'
import { memo } from 'react'

import useStyles from './styles'

const benefitsMap = [
  {
    text: 'No post office lines',
    icon: Users,
  },
  {
    text: 'We accept returns from any retailer or carrier',
    icon: RetailersGroup,
  },
  {
    text: 'Outbound shipping',
    icon: BrandsWithBox,
  },
]

function Benefits() {
  const classes = useStyles()

  return (
    <section className={classes.benefits}>
      <div className={classes.sectionContainer}>
        <h2 className={classes.benefitsTitle}>
          <span>Online returns</span>
          <br />
          just got a lot easier
        </h2>
        <p className={classes.text}>
          Online returns are a hassle. We've partnered with Returnmates to make it easy! Want to
          skip the post office trip? No packaging or physical return label required but if you have
          them, even better! Just place your return in the Luxer locker and we'll take care of the
          rest!
        </p>
        <div className={classes.benefitsRow}>
          {benefitsMap.map((benefit, i) => (
            <div key={i} className={classes.benefitBox}>
              <benefit.icon className={classes.benefitIcon} />
              <p className={classes.benefitText}>{benefit.text}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export default memo(Benefits)
