import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(() => ({
  spinnerHandler: {
    width: '100%',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}))

export default useStyles
