import {
  CrossDelimiter,
  LuxerOneLogo,
  ReturnmatesLogo,
} from '@returnmates/ui-core/src/components/imageComponents'
import { memo } from 'react'

import useStyles from './styles'

function Header() {
  const classes = useStyles()
  return (
    <div className={classes.header}>
      <div className={classes.headerBackground} />
      <div className={classes.logoWrap}>
        <div className={classes.partnerLogoWrap}>
          <LuxerOneLogo className={classes.luxerLogo} />
          <CrossDelimiter className={classes.cross} />
        </div>
        <ReturnmatesLogo className={classes.logo} />
      </div>
    </div>
  )
}

export default memo(Header)
