import Button from '@returnmates/ui-core/src/components/Button'
import Modal from '@returnmates/ui-core/src/components/Modal'
import useCheckScreenWidth from '@returnmates/ui-core/src/hooks/useCheckScreenWidth'
import { memo, useCallback, useState } from 'react'

import { SHIPSWAY_URL } from '../../constants/paths'
import useStyles from './styles'

function NotSchedulePickupModal() {
  const classes = useStyles()
  const [isOpen, setIsOpen] = useState(true)
  const isFullScreenModal = useCheckScreenWidth({ minWidth: 450 })

  const handleClose = useCallback(() => {
    setIsOpen(false)
  }, [])

  const onClick = useCallback(() => {
    window.open(SHIPSWAY_URL, '_blank')?.focus()
  }, [])

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      fullScreen={isFullScreenModal}
      paperClassName={classes.paper}
    >
      <div className={classes.modal}>
        <div className={classes.modalContainer}>
          <div className={classes.leftPart}>
            <img src="/assets/images/noScheduleModalImage.jpg" alt="" />
          </div>
          <div className={classes.rightPart}>
            <img src="/assets/images/box.png" alt="box" />
            <p className={classes.title}>
              The Returnmates Home Pickup Service has been discontinued
            </p>
            <p className={classes.subTitle}>
              We’re focusing our efforts on supporting shoppers through partnerships with some of
              the world’s largest brands. Have a question? Reach out to{' '}
              <a href="mailto:hello@returnmates.com">hello@returnmates.com</a>
            </p>
            <Button className={classes.button} label="Take me there" onClick={onClick} />
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default memo(NotSchedulePickupModal)
