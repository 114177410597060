import { PromoDialogTypes } from '@returnmates/client-core/src/type'

interface Props {
  dialogType: PromoDialogTypes | null
}

const PromoMobileBottom = ({ dialogType }: Props): JSX.Element => {
  if (dialogType === PromoDialogTypes.rtr) {
    return (
      <span>
        *Offer applies to <b>Subscription</b> only
      </span>
    )
  } else if (dialogType === PromoDialogTypes.mail) {
    return (
      <span>
        *Offer applies to <b>Pay as You Go</b> only
      </span>
    )
  }

  return <></>
}

export default PromoMobileBottom
